/**
 * Author: Quan Vo
 * Date: 2020-01-07
 */

import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

export const CellBorder = styled(TableCell)`
  border-right: 1px solid #e0e0e0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Content = styled.div`
  padding: 20px;
  flex: 1;
`;

export const Header = styled.div`
  align-self: flex-end;
`;

export const Footer = styled.div`
  align-self: flex-end;
  button {
    margin: 10px;
  }
`;

export const StyledButton = styled.div`
  height: 40px;
  width: 340px;
  border-radius: 20px;
  background-color: #ad5e99;
  transition: all 0.5s;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 16px;
  color: white;

  &:hover {
    background-color: #94347c;
  }
`;

export const ButtonEnhance = styled.div`
  height: 40px;
  min-width: ${props => (props.minWidth ? props.minWidth : '160px')};
  border-radius: 4px;
  background-color: ${props =>
    props.background ? props.background : '#102B43'};
  transition: all 0.5s;
  cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
  text-align: center;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  font-weight: 600;
  padding: 10px 16px;
  color: ${props => (props.color ? props.color : 'white')}
  &:hover {
    background-color: ${props =>
      props.backgroundHover ? props.backgroundHover : '#1a4266'}
  };
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  margin: 5px;
  border: ${props => (props.border ? props.border : 'none')};
`;

export const ChipStatus = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  width: fit-content;
  background-color: ${props => props.color || '#62CAA4'};
`;

const StatusWrapper = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  width: fit-content;
  background-color: ${props => {
    switch (props.status) {
      case 'Temp':
      case 'Pending': {
        return '#CACFD3';
      }
      case 'Closed':
      case 'Reject':
      case 'InActive': {
        return '#EA6B75';
      }
      case 'Open':
      case 'Approved':
      case 'Active': {
        return '#62CAA4';
      }
      default:
        return '#EA6B75';
    }
  }};
`;

export { StatusWrapper };
