import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  Typography,
  withStyles,
  Divider,
  FormHelperText,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { ENTER_KEY, InputModal } from '../../../common';
import styled from 'styled-components';
import authDispatcher from '../../action/auth';
import Image from '../../../../components/Image';
import './styles.scss';
import { ButtonEnhance } from '../../../common/componentUI/commonStyleComponents';
import { EmailIcon, LockIcon, EyeHide, EyeShow } from '../../../../assets/svg';
import { urlLabel } from '../../../../enum/PermissionEnum';

const styles = () => ({
  formControl: {
    marginTop: '0px'
  }
});

const Login = ({ classes, history }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorField, setErrorField] = useState(-1);
  const [showPassword, setShowPassword] = useState(false);

  const setError = (isError, index) => {
    if (isError) {
      setShowError(true);
      setErrorField(index);
    } else {
      setShowError(false);
      setErrorField(-1);
    }
  };

  const _hideError = () => {
    if (showError) {
      setShowError(false);
    }
  };

  const isErrorField = index => showError && errorField === index;

  const toRegister = () => history.push(urlLabel.register);

  const handleLogin = async () => {
    if (!userName) setError(true, 0);
    else if (!password) setError(true, 1);
    else {
      authDispatcher.login(userName, password, res => {
        if (!res) return;
        authDispatcher.checkOtp(history, res);
        _hideError();
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100vh'
      }}
    >
      <Wrapper>
        <Content>
          <FormControlStyle className={classes.formControl}>
            <TextFieldStyle
              name="Username"
              margin="normal"
              label="Username"
              autoComplete="username"
              value={userName}
              onChange={e => setUserName(e.target.value)}
            />
            {isErrorField(0) && (
              <FormHelperText style={{ color: '#b00020' }}>
                Username must not be empty
              </FormHelperText>
            )}
          </FormControlStyle>
          <FormControlStyle className={classes.formControl}>
            <TextFieldStyle
              type={showPassword ? '' : 'password'}
              name="Password"
              margin="normal"
              label="Password"
              autoComplete="password"
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <>
                      <EyeHide
                        style={{ cursor: 'pointer' }}
                        hidden={showPassword}
                        onClick={() => setShowPassword(true)}
                      />
                      <EyeShow
                        style={{ cursor: 'pointer' }}
                        hidden={!showPassword}
                        onClick={() => setShowPassword(false)}
                      />
                    </>
                  </InputAdornment>
                )
              }}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => e.keyCode === ENTER_KEY && handleLogin()}
            />
            {isErrorField(1) && (
              <FormHelperText style={{ color: '#b00020' }}>
                Password must not be empty
              </FormHelperText>
            )}
          </FormControlStyle>
          <CustomTypography
            style={{
              marginLeft: 6,
              cursor: 'pointer',
              fontWeight: 500,
              alignSelf: 'flex-end'
            }}
            onClick={() => history.push('/forget-password')}
          >
            Forgot Password?
          </CustomTypography>
          <ButtonEnhance
            onClick={handleLogin}
            style={{ margin: 0, marginTop: 10 }}
          >
            Login
          </ButtonEnhance>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
          >
            <CustomTypography style={{ color: '#8F9BA9', fontWeight: 500 }}>
              Don't have an account yet?
            </CustomTypography>
            <CustomTypography
              style={{
                marginLeft: 6,
                cursor: 'pointer',
                fontWeight: 500
              }}
              onClick={toRegister}
            >
              Sign up
            </CustomTypography>
          </div>
        </Content>
      </Wrapper>
    </div>
  );
};

const CustomTypography = styled(Typography)`
  font-size: 14px !important;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 440px;
  margin-top: 10%;
`;

const Header = styled.div`
  justify-content: center;
  height: 70px;
  display: flex;
  align-items: center;
`;

const TextFieldStyle = styled(TextField)`
  margin: 0px;
`;

const FormControlStyle = styled(FormControl)`
  margin-top: 12px;
`;
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default withStyles(styles)(connect(mapStateToProps)(Login));
