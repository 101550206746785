import React, { useEffect, useCallback, useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  Table,
  Paper,
  TableContainer as MUTableContainer,
  TableBody,
  TablePagination,
  Typography,
  TableRow,
  IconButton,
  Select,
  Tooltip,
  Popover,
  TextField,
  Button
} from '@material-ui/core';
import { debounce } from 'lodash';
import { ButtonEnhance } from '../../module/common/componentUI/commonStyleComponents';
import { renderIf } from '../../helpers';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  LastPage,
  FirstPage,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  MoreHorizRounded
} from '@material-ui/icons';

const useStyles = makeStyles({
  contained: {
    boxShadow: 'none'
  },
  head: {
    fontWeight: 600
  }
});

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center'
  }
}));

const TableContainer = ({
  header: Header,
  renderRow,
  query = () => {},
  paging = {},
  data = [],
  setSearchKey,
  searchKey,
  onAddNew,
  sortOption,
  filterOption,
  title = '',
  toolbar = true,
  ToolbarComponent
}) => {
  const classes = useStyles();
  const onChangeSearch = e => {
    setSearchKey(e.target.value);
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    // query(search, pagingData, sortOptionData, filterOptionData);
    query(search, pagingData, filterOptionData);
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  const TablePaginationActions = props => {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage } = props;

    const handleFirstPageButtonClick = () => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: 0
      });
    };

    const handleBackButtonClick = () => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: page - 1
      });
    };

    const handleNextButtonClick = () => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: page + 1
      });
    };

    const handleLastPageButtonClick = () => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: paging.totalPage - 1
      });
    };

    const handleGoToPage = page => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: page - 1
      });
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <PageSelector {...props} handleGoToPage={handleGoToPage} />
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  };

  return (
    <MUTableContainer component={Paper} style={{ marginBottom: 20 }}>
      {/* Table toolbar */}
      {renderIf(toolbar)(
        <div className="table-container-header">
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 600
            }}
          >
            {title}
          </Typography>
          {onAddNew && (
            <ButtonEnhance
              style={{
                margin: 5,
                paddingLeft: 40,
                paddingRight: 40
              }}
              onClick={onAddNew}
            >
              Create New
            </ButtonEnhance>
          )}
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <div className="search-container">
          <Input
            allowClear
            maxLength={200}
            value={searchKey}
            onChange={onChangeSearch}
            placeholder="Search"
            prefix={
              <SearchOutlined
                style={{ color: 'rgba(0,0,0,.25)', fontSize: 16 }}
                type="search"
              />
            }
          />
        </div>
        {ToolbarComponent}
      </div>

      <Table>
        {Header && <Header />}
        <TableBody>
          {data.map(d => (
            <TableRow
              key={d.id}
              hover
              classes={{
                head: classes.head
              }}
            >
              {renderRow(d)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50, 100, 200]}
        component="div"
        count={paging.totalItem}
        rowsPerPage={paging.pageSize}
        page={paging.pageIndex}
        total={paging.totalPage}
        backIconButtonProps={{
          'aria-label': 'Previous Page'
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page'
        }}
        onChangePage={(e, page) => {
          e &&
            fetchData(searchKey, {
              ...paging,
              pageIndex: page
            });
        }}
        onChangeRowsPerPage={e => {
          fetchData(searchKey, {
            ...paging,
            pageSize: e.target.value
          });
        }}
        ActionsComponent={TablePaginationActions}
      />
    </MUTableContainer>
  );
};

const usePageSelectorStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  selectorHeader: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#e9e9e9'
  }
}));

const PageSelector = ({ count, page, rowsPerPage, handleGoToPage }) => {
  const classes = usePageSelectorStyles();
  const totalPage = Math.ceil(count / rowsPerPage);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(page + 1);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onGoToPage = () => {
    handleGoToPage(currentPage);
    handleClose();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => setCurrentPage(page + 1), 200);
  };
  return (
    <div>
      <Tooltip title="Go to page">
        <IconButton onClick={handleClick} aria-label="go to page">
          <MoreHorizRounded />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paper
        }}
      >
        <div className={classes.selectorHeader}>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 600
            }}
          >
            Go to page {`(1 -  ${totalPage})`}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant="outlined"
            value={currentPage}
            type="number"
            inputProps={{ min: 1, max: totalPage }}
            style={{ minWidth: 100 }}
            onChange={e => setCurrentPage(e.target.value)}
          />
          <ButtonEnhance
            style={{
              margin: 10,
              minWidth: 70,
              height: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 15
            }}
            onClick={onGoToPage}
          >
            Go
          </ButtonEnhance>
        </div>
      </Popover>
    </div>
  );
};
export default TableContainer;
