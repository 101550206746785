import React, { PureComponent } from 'react';
import { Breadcrumb } from 'antd';
import { withRouter, NavLink } from 'react-router-dom';
import './styles.scss';
import _ from 'lodash';
import { RightOutlined } from '@ant-design/icons';
class BreadcrumbAnt extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;
    if (
      prevLocation.pathname === location.pathname &&
      prevLocation.search === location.search &&
      prevLocation.hash === location.hash &&
      prevLocation.key !== location.key
    ) {
      window.location.reload();
    }
  }
  render() {
    let xhtml = null;
    const { hidden, location } = this.props;
    const routes = location.pathname.split('/').filter(i => i);
    const breadcrumbs = (routes || []).map((item, index) => {
      const router = `/${routes.slice(0, index + 1).join('/')}`;
      const text = _.startCase(item.replace(/-/g, '_'));
      return {
        text,
        router
      };
    });

    if (!hidden) {
      xhtml = (
        <Breadcrumb className="breadcrum-and--wrapper">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          {(breadcrumbs || []).map(br => {
            return (
              br.router && (
                <Breadcrumb.Item key={br.router}>
                  <NavLink
                    to={`${br.router}`}
                    activeStyle={{
                      color: '#192637'
                    }}
                  >
                    {br.text.replace('_', ' ')}
                  </NavLink>
                </Breadcrumb.Item>
              )
            );
          })}
        </Breadcrumb>
      );
    }
    return xhtml;
  }
}

export default withRouter(BreadcrumbAnt);
