import React from 'react';
import { Modal, Typography } from '@material-ui/core';
import ModalPresenter from './ModalPresenter';
import { DeleteRounded } from '@material-ui/icons';
import { get } from 'lodash';

const DeleteModal = ({
  showModal,
  onClose,
  selectedItem,
  onDelete,
  modalName,
  keyTitle
}) => {
  return (
    <Modal open={showModal} onClose={onClose}>
      <div>
        <ModalPresenter
          onClose={onClose}
          Icon={<DeleteRounded style={{ fontSize: 80, color: '#102B43' }} />}
          title={`Delete '${get(
            selectedItem,
            keyTitle || 'name'
          )}' ${modalName}?`}
          onClick={onDelete}
        >
          <Typography>Click “Continue” to delete</Typography>
        </ModalPresenter>
      </div>
    </Modal>
  );
};

export default DeleteModal;
