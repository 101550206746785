import { synthesize } from 'redux-dispatcher';
const mapDispatchToAC = {
  getDataSuccess: data => ({
    data
  }),
  getData: (search, paging) => async ({ Api, getState }) => {
    let pagingData = paging || getState().site.paging;
    let { result, status } = await Api.get(`Sites`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search
    });
    if (status === 200) {
      siteDispatcher.getDataSuccess(result);
    }
  },
  getSiteById: (id, callback) => async ({ Api, getState }) => {
    let { result, status } = await Api.get(`Sites/${id}`);
    if (status === 200) callback(result);
  },
  updateSite: (id, data, searchKey, callback) => async ({
    Api,
    getState,
    toastr
  }) => {
    let { result, status } = await Api.put(`Sites/${id}`, data);
    if (status === 200) {
      toastr.success('Success');
      siteDispatcher.getData(searchKey);
      callback(result);
    }
  },
  getAllUsers: () => async ({ Api, getState }) => {
    let { result, status } = await Api.get(`Users/filter`);
    if (status === 200) siteDispatcher.getAllUsersSuccess(result);
  },
  getAllUsersSuccess: data => ({
    data
  })
};

const siteDispatcher = synthesize('site', mapDispatchToAC);
export default siteDispatcher;
