import React, { useState, useEffect } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Dialog
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import siteDispatcher from '../action/sites';
import { CreateRounded } from '@material-ui/icons';
import UpdateForm from './UpdateForm';
const SiteManagement = () => {
  const data = useSelector(state => state.site.list);
  const paging = useSelector(state => state.site.paging);
  const [updateForm, setUpdateForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [hiddenRows, setHiddenRows] = useState({});
  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          style={{ paddingLeft: 20 }}
          label="Site Name"
          stateValue="siteName"
        />
        <TableCellFiltedWrapper label="Region" />
        <TableCellFiltedWrapper label="Postal Code" />
        <TableCellFiltedWrapper label="Address" />
        <TableCellFiltedWrapper label="Email" />
        <TableCellFiltedWrapper label="Mobile" />
        <TableCellFiltedWrapper label="Store Manager" />
        <TableCellFiltedWrapper label="Store Contact" />
        <TableCellFiltedWrapper label="CSM" />
        <TableCellFiltedWrapper label="Note" />
        <TableCellFiltedWrapper label="Budgeted Hour" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <Dialog
        open={!!updateForm}
        onClose={() => setUpdateForm(false)}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        style={{ padding: 0 }}
      >
        <UpdateForm
          selectedItem={selectedItem}
          regionForm={updateForm}
          searchKey={searchKey}
          onClose={() => setUpdateForm(false)}
        />
      </Dialog>
      <TableContainer
        title="Site Management"
        query={siteDispatcher.getData}
        header={Header}
        paging={paging}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        data={data}
        renderRow={d => (
          <>
            <TableCell style={{ paddingLeft: 20 }}>{d.siteName}</TableCell>
            <TableCell>{d.region}</TableCell>
            <TableCell>{d.postCode}</TableCell>
            <TableCell>{d.address}</TableCell>
            <TableCell style={{ maxWidth: 180, overflowWrap: 'break-word' }}>
              {d.email}
            </TableCell>
            <TableCell>{d.moblie}</TableCell>
            <TableCell>{d.storeManager}</TableCell>
            <TableCell>{d.storePhone}</TableCell>
            <TableCell>{d.csm}</TableCell>
            <TableCell>{d.notes}</TableCell>
            <TableCell>{d.budgetedHour}</TableCell>
            <TableCell>
              <IconButton
                onClick={() => {
                  setSelectedItem(d);
                  setUpdateForm(true);
                }}
              >
                <Tooltip title="Update Region">
                  <CreateRounded />
                </Tooltip>
              </IconButton>
            </TableCell>
          </>
        )}
      />
    </>
  );
};

export default SiteManagement;
