import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  withStyles,
  IconButton,
  Tooltip
} from '@material-ui/core';
import {
  FormatIndentDecreaseRounded,
  FormatIndentIncreaseRounded,
  ExitToAppRounded
} from '@material-ui/icons';
import MenuItem from './MenuItem';
import { withRouter } from 'react-router-dom';
import authDispatcher from '../../auth/action/auth';
import { Dropdown, Menu as AntMenu } from 'antd';
import fetchHelper from '../../../helpers/FetchHelper';
import BreadcrumbAnt from '../../../components/BreadcrumbAnt';
import { CompanyLogo } from '../../../assets/svg';
import './private.scss';
import ChangePasswordForm from '../profile/component/ChangePasswordForm';

const drawerWidth = 250;

const styles = theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 0
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    background: 'white'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    backgroundColor: '#102B43',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    zIndex: 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
    background: '#F6F8FE'
  },
  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320,
    marginTop: 20
  },
  h5: {
    marginBottom: theme.spacing(2)
  },
  avatar: {
    width: 24,
    height: 24
  },
  dropDown: {
    width: 11,
    height: 11
  },
  wrapperAction: {
    display: 'flex'
  }
});

class PrivateLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      anchorMenuEl: null,
      isShowNotification: null,
      showChangePW: false,
      showChangeEmail: false
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: !this.state.open });
  };

  handleMenuClose = () => {
    this.setState({ anchorMenuEl: null });
  };
  handleNotificationClose = () => {
    this.setState({ isShowNotification: null });
  };

  handleMenuOpen = event => {
    this.setState({ anchorMenuEl: event.currentTarget });
  };

  componentDidMount() {
    const { accessToken, userInfo } = this.props;
    if (accessToken) {
      fetchHelper.addDefaultHeader('Authorization', `Bearer ${accessToken}`);
    }
    // authDispatcher.getUserDetail();
  }

  goToProfile = () => {
    this.props.history.push('profile-manage');
  };

  renderDropdownOption = () => {
    return (
      <AntMenu className="header-account-menu">
        <AntMenu.Item onClick={this.goToProfile}>
          <Typography>Profile</Typography>
        </AntMenu.Item>
        <AntMenu.Item onClick={() => this.setState({ showChangePW: true })}>
          <Typography>Change Password</Typography>
        </AntMenu.Item>
      </AntMenu>
    );
  };

  render() {
    const { classes, reloadPageRequest, userInfo } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            <IconButton onClick={this.handleDrawerClose}>
              {this.state.open ? (
                <FormatIndentDecreaseRounded />
              ) : (
                <FormatIndentIncreaseRounded />
              )}
            </IconButton>
            <div />

            <div className={classes.wrapperAction}>
              <IconButton onClick={() => authDispatcher.logout()}>
                <Tooltip title="Logout">
                  <ExitToAppRounded />
                </Tooltip>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose
            )
          }}
          open={this.state.open}
        >
          <MenuItem
            openDrawer={this.state.open}
            reloadPageRequest={reloadPageRequest}
          />
        </Drawer>
        <Drawer
          anchor="right"
          width="50%"
          open={this.state.showChangePW}
          onClose={() => this.setState({ showChangePW: false })}
          PaperProps={{
            style: {
              width: 720
            }
          }}
        >
          <ChangePasswordForm
            onClose={() => this.setState({ showChangePW: false })}
          />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <BreadcrumbAnt />
          <div className={classes.tableContainer}>{this.props.children}</div>
        </main>
      </div>
    );
  }
}

PrivateLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    userInfo: state.auth.userInfo
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(PrivateLayout)
  )
);
