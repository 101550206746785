import React from 'react';
import PropTypes from 'prop-types';
function AddImageOutlineIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>DB75E92C-835B-4BEF-BDBC-E3218F0AE737@1.5x</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="🌈-Style-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons"
          transform="translate(-622.000000, -160.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Colors-Copy" transform="translate(22.000000, 20.000000)">
            <path
              d="M617,145.003475 L617,147.995552 C617,147.995552 615.01,148.005559 615,147.995552 L615,145.003475 L612,145.003475 C612,145.003475 612.01,143.012092 612,143.002085 L615,143.002085 L615,140 L617,140 L617,143.002085 L620,143.002085 L620,145.003475 L617,145.003475 Z M613.8125,149.375 L613.8125,146.1875 L610.625,146.1875 L610.625,143 L602.125,143 C600.95625,143 600,143.95625 600,145.125 L600,157.875 C600,159.04375 600.95625,160 602.125,160 L614.875,160 C616.04375,160 617,159.04375 617,157.875 L617,149.375 L613.8125,149.375 Z M602,158 L605.25,154 L607.416667,157 L610.666667,153 L615,158 L602,158 Z"
              id="ic_image_add"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

AddImageOutlineIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

AddImageOutlineIcon.defaultProps = {
  width: '20px',
  height: '20px',
  color: '#0B0E12'
};

export default AddImageOutlineIcon;
