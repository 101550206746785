/**
 * Author: Quan Vo
 * Date: 2020-01-08
 */

import UAParser from 'ua-parser-js';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { isEmpty, get } from 'lodash';
import { DATE_FORMAT } from '../module/common';
import moment from 'moment';

export const devLog = (...args) =>
  process.env.NODE_ENV === 'development' && console.log(...args);

/*-------------------------------------------------------------------*/

/**
 * https://github.com/ajwhite/render-if/blob/master/renderIf.js
 */

const isFunction = input => typeof input === 'function';

export const renderIf = predicate => elemOrThunk =>
  predicate ? (isFunction(elemOrThunk) ? elemOrThunk() : elemOrThunk) : null;

/*-------------------------------------------------------------------*/

export const preventDecimalInput = e =>
  (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault();

const parser = new UAParser();
const userAgentInfo = parser.getResult();

export const isSafari = () => {
  return (
    userAgentInfo.browser.name === 'Safari' ||
    userAgentInfo.browser.name === 'Mobile Safari'
  );
};

export const isCompatibleChrome = () => {
  if (userAgentInfo.browser.name === 'Chrome') {
    const major = +userAgentInfo.browser.major;
    if (major >= 72) return true;
  }
  return false;
};

export const isFirefox = () => {
  return userAgentInfo.browser.name === 'Firefox';
};

export const getNationalNumber = (
  text = '',
  countryCode,
  fullNumber = false,
  defaultValue = text
) => {
  if (text === null) return '';
  let rs = getNumberInfo(text, countryCode);
  if (!fullNumber) return get(rs, '[0]', defaultValue);
  else return get(rs, '[1]', defaultValue);
};

export const getNumberInfo = (text, countryCode) => {
  let rs = findPhoneNumbersInText(text, countryCode);
  if (isEmpty(rs)) {
    return [];
  }
  return [rs[0].number.nationalNumber, rs[0].number.number];
};

export const blobUrl = blob => {
  if (!blob.url) {
    blob.url = URL.createObjectURL(blob);
  }
  return blob.url;
};

export const momentFormatted = (date, formatType = DATE_FORMAT) => {
  let value = moment(date);
  if (!value.isValid()) return null;
  else return value.format(formatType);
};

export const durationFormatted = (startTime, endTime) => {
  if (!startTime || !endTime) return null;

  let startMoment = moment(startTime);
  let endMoment = moment(endTime);

  if (!startMoment.isValid() || !endMoment.isValid()) return null;
  let duration = moment.duration(endMoment.diff(startMoment)).asMilliseconds();
  return moment.utc(duration).format('HH:mm:ss');
};
