import { synthesize } from 'redux-dispatcher';
import { get } from 'lodash';
const mapDispatchToAC = {
  getDataSuccess: data => ({
    data
  }),
  getData: (search, paging) => async ({ Api, getState }) => {
    let pagingData = paging || getState().siteTrack.paging;
    let { result, status } = await Api.get(`SiteOrders`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search
    });
    if (status === 200) {
      siteTrackDispatcher.getDataSuccess(result);
    }
  },
  getSiteOrderDetail: (id, onSuccess) => async ({ Api }) => {
    let { result, status } = await Api.get(`SiteOrders`, {
      id
    });
    if (status === 200) {
      onSuccess(get(result, 'list[0]'));
    }
  },
  deleteSiteOrder: (id, searchKey) => async ({ Api, getState, toastr }) => {
    let { result, status } = await Api.delete(`SiteOrders/id`, {
      id
    });
    if (status === 200) {
      let { paging } = getState().siteTrack;
      siteTrackDispatcher.getData(searchKey, paging);
      toastr.success('Success');
    }
  }
};

const siteTrackDispatcher = synthesize('siteTrack', mapDispatchToAC);
export default siteTrackDispatcher;
