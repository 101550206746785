import { TableCell, Tooltip } from '@material-ui/core';
import React from 'react';
import { EyeShow, EyeHide } from '../../../assets/svg';

const TableCellFilted = ({
  label,
  stateValue,
  hiddenRows,
  setHiddenRows,
  style = {}
}) => {
  return (
    <TableCell style={style}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!hiddenRows[stateValue] && label}
      </div>
    </TableCell>
  );
};

export default TableCellFilted;
