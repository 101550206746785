import React, { useState, useEffect } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  IconButton,
  Menu
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import regionDispatcher from '../action/region';
import RegionForm from './RegionForm';
import { MoreVertRounded } from '@material-ui/icons';
import MenuAction from './MenuAction';
import DeleteModal from '../../../components/DeleteModal';
import RegionSitesDetail from './RegionSitesDetail';
const RegionManagement = () => {
  const data = useSelector(state => state.region.list);
  const paging = useSelector(state => state.region.paging);
  const [regionForm, setRegionForm] = useState(false);
  const [showSitesDetail, setShowSitesDetail] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [hiddenRows, setHiddenRows] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          style={{ paddingLeft: 20 }}
          label="Region Name"
          stateValue="name"
        />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const onShowModalForm = (value, isEdit = false) => {
    setAnchorEl(null);
    setRegionForm(value);
    setEdit(isEdit);
  };

  const handleDeleteRegion = () => {
    setDeleteModal(false);
    regionDispatcher.deleteRegion(selectedItem.id, searchKey);
  };

  const onShowSiteModal = value => {
    setAnchorEl(null);
    setShowSitesDetail(value);
  };

  return (
    <>
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          selectedItem={selectedItem}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteRegion}
          modalName="Region"
        />
      )}
      <Dialog
        open={!!regionForm}
        onClose={() => setRegionForm(false)}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        style={{ padding: 0 }}
      >
        <RegionForm
          selectedItem={selectedItem}
          regionForm={regionForm}
          isEdit={isEdit}
          searchKey={searchKey}
          onClose={() => setRegionForm(false)}
        />
      </Dialog>
      <Dialog
        open={!!showSitesDetail}
        onClose={() => setShowSitesDetail(false)}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullWidth
        style={{ padding: 0 }}
      >
        <RegionSitesDetail
          selectedItem={selectedItem}
          onClose={() => setShowSitesDetail(false)}
        />
      </Dialog>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuAction
          onShowSiteModal={onShowSiteModal}
          onShowModal={onShowModalForm}
          onDel={() => {
            setAnchorEl(null);
            setDeleteModal(true);
          }}
        />
      </Menu>
      <TableContainer
        title="Region Management"
        query={regionDispatcher.getData}
        header={Header}
        paging={paging}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        data={data}
        onAddNew={() => {
          setSelectedItem({});
          setEdit(true);
          setRegionForm(true);
        }}
        renderRow={d => (
          <>
            <TableCell style={{ paddingLeft: 20 }}>
              {!hiddenRows.name && d.name}
            </TableCell>

            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setSelectedItem(d);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
    </>
  );
};

export default RegionManagement;
