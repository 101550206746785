import Enum from './Enum';

export const urlLabel = Enum({
  home: '',
  login: 'login',
  register: 'register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  logout: 'logout',
  regionsManagement: 'regions',
  sitesManagement: 'site',
  siteOrder: 'runsheet',
  siteTracker: 'tracker'
});

export const rolePermission = Enum({
  Admin: [
    urlLabel.home,
    urlLabel.logout,
    urlLabel.regionsManagement,
    urlLabel.sitesManagement,
    urlLabel.siteTracker
  ],
  Client: [urlLabel.home, urlLabel.logout, urlLabel.siteTracker]
});
