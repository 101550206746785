import React, { useState } from 'react';
import ClearIcon from '@material-ui/icons/ClearRounded';
import TextField from '@material-ui/core/TextField';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { get, isEmpty } from 'lodash';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import LoadingButton from '../../../components/LoadingButton';
import siteDispatcher from '../action/sites';
import regionDispatcher from '../../region/action/region';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Api from '../../../helpers/Api';

const titleContainerStyle = {
  height: 60,
  padding: '16px 20px 17px 20px',
  fontSize: 18,
  fontWeight: 600,
  color: '#192637',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px #CACFD3 solid'
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 40px 20px',
  fontSize: 14
};

const footerContainerStyle = {
  display: 'flex',
  padding: '10px 20px 10px 20px',
  flexDirection: 'row-reverse',
  borderTop: '1px #CACFD3 solid'
};

const useStyles = makeStyles({
  root: {
    padding: '0px !important'
  }
});

const UpdateForm = ({
  onClose,
  selectedItem,
  regionForm: detailModal,
  editable = true,
  searchKey
}) => {
  const classes = useStyles();
  const isAdd = editable && isEmpty(selectedItem);
  const [editableItem, setEditableItem] = useState({});
  const [error, setError] = useState({});
  const [showLoading] = useState(false);
  const [region, setRegion] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [postalCodes, setPostalCodes] = useState([]);
  const listRegion = useSelector(state => state.region.list);
  useEffect(() => {
    regionDispatcher.getData(undefined, undefined, true);
    if (!isEmpty(selectedItem)) {
      siteDispatcher.getSiteById(selectedItem.pkSiteID, async siteDetail => {
        let fetchRegionDetail = Api.get(`Regions/id?id=${siteDetail.regionId}`);
        let [regionDetailRs] = await Promise.all([fetchRegionDetail]);
        if (regionDetailRs.status === 200 && regionDetailRs.result)
          setRegion(regionDetailRs.result);
        setEditableItem(siteDetail);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!region) {
      setPostalCode('');
      setPostalCodes([]);
    } else {
      fetchPostalCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  const fetchPostalCodes = async () => {
    let { result, status } = await Api.get(`PostalCodes`, {
      regionId: region.id
    });
    if (status === 200) {
      setPostalCodes(result.list);
    }
  };
  const onUpdate = () => {
    if (!region) {
      return setError({
        name: 'Region is required'
      });
    } else
      siteDispatcher.updateSite(
        editableItem.pkSiteID,
        {
          region: region.name
        },
        searchKey,
        rs => {
          onClose();
        }
      );
  };

  const renderFooter = () => {
    return (
      <>
        <LoadingButton showLoader={showLoading} onClick={onUpdate}>
          Save
        </LoadingButton>
        <ButtonEnhance
          onClick={onClose}
          color="#192637"
          background="#E9E9E9"
          backgroundHover="#bababa"
        >
          Cancel
        </ButtonEnhance>
      </>
    );
  };

  return (
    <DialogContent className={classes.root}>
      <div style={titleContainerStyle}>
        <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        {selectedItem.siteName || 'Create New Region'}
      </div>
      <div style={bodyContainerStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              value={region}
              onChange={(event, newValue) => {
                setRegion(newValue);
              }}
              id="region"
              fullWidth
              options={listRegion}
              getOptionLabel={option => option.name || ''}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Region"
                  error={error.name ? true : false}
                  helperText={error.name}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div style={footerContainerStyle}>{renderFooter()}</div>
    </DialogContent>
  );
};

export default UpdateForm;
