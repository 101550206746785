import React, { useState } from 'react';
import ClearIcon from '@material-ui/icons/ClearRounded';
import TextField from '@material-ui/core/TextField';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { get, isEmpty } from 'lodash';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import LoadingButton from '../../../components/LoadingButton';
import regionDispatcher from '../action/region';

const titleContainerStyle = {
  height: 60,
  padding: '16px 20px 17px 20px',
  fontSize: 18,
  fontWeight: 600,
  color: '#192637',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px #CACFD3 solid'
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 40px 20px',
  fontSize: 14
};

const footerContainerStyle = {
  display: 'flex',
  padding: '10px 20px 10px 20px',
  flexDirection: 'row-reverse',
  borderTop: '1px #CACFD3 solid'
};

const useStyles = makeStyles({
  root: {
    padding: '0px !important'
  }
});

const RegionForm = ({
  onClose,
  selectedItem,
  regionForm: detailModal,
  isEdit: editable,
  searchKey
}) => {
  const classes = useStyles();
  const variant = detailModal.isViewing ? 'filled' : 'standard';
  const isAdd = editable && isEmpty(selectedItem);
  const [editableItem, setEditableItem] = useState({});
  const [error, setError] = useState({});
  const [showLoading] = useState(false);
  const { fullName: useFullName } = useSelector(state => state.auth.userInfo);
  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      setEditableItem(selectedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreate = () => {
    if (!editableItem.name) {
      return setError({
        name: 'Name is required'
      });
    } else regionDispatcher.createRegion(editableItem, searchKey, onClose);
  };

  const onUpdate = () => {
    if (!editableItem.name) {
      return setError({
        name: 'Name is required'
      });
    } else regionDispatcher.updateRegion(editableItem, searchKey, onClose);
  };

  const renderFooter = () => {
    if (!editable) {
      return <ButtonEnhance onClick={onClose}>Close</ButtonEnhance>;
    } else if (isAdd)
      return (
        <>
          <LoadingButton showLoader={showLoading} onClick={onCreate}>
            Create
          </LoadingButton>
          <ButtonEnhance
            onClick={onClose}
            color="#192637"
            background="#E9E9E9"
            backgroundHover="#bababa"
          >
            Cancel
          </ButtonEnhance>
        </>
      );
    else {
      return (
        <>
          <LoadingButton showLoader={showLoading} onClick={onUpdate}>
            Save
          </LoadingButton>
          <ButtonEnhance
            onClick={onClose}
            color="#192637"
            background="#E9E9E9"
            backgroundHover="#bababa"
          >
            Cancel
          </ButtonEnhance>
        </>
      );
    }
  };

  return (
    <DialogContent className={classes.root}>
      <div style={titleContainerStyle}>
        <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        {selectedItem.name || 'Create New Region'}
      </div>
      <div style={bodyContainerStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={error.name}
              variant={variant}
              id="product-name-input"
              label="Region Name"
              disabled={!editable}
              value={editableItem.name || ''}
              onChange={e =>
                setEditableItem({
                  ...editableItem,
                  name: e.target.value
                })
              }
              helperText={error.name}
            />
          </Grid>
        </Grid>
      </div>
      <div style={footerContainerStyle}>{renderFooter()}</div>
    </DialogContent>
  );
};

export default RegionForm;
