import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  RoomRounded,
  MyLocationRounded,
  ReorderRounded,
  MapRounded
} from '@material-ui/icons';
import { SettingIcon } from '../../../assets/svg';
import { urlLabel, rolePermission } from '../../../enum/PermissionEnum';
import authDispatcher from '../../auth/action/auth';
import './menu.scss';
import { roleType } from '../../../enum/RequestEnum';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  secondary: {
    color: '#000000de'
  }
});

const MENU_ITEMS = [
  {
    title: 'Regions',
    value: urlLabel.regionsManagement,
    icon: RoomRounded
  },
  {
    title: 'Sites',
    value: urlLabel.sitesManagement,
    icon: MyLocationRounded
  },
  {
    title: 'Tracker',
    value: urlLabel.siteTracker,
    icon: MapRounded
  }
];

const MenuItem = ({ history, location }) => {
  const [tabSelected, setTabSelected] = useState('');
  const [menuItems, setMenuItems] = useState(MENU_ITEMS);
  const userRole = useSelector(state => state.auth.roleType);
  useEffect(() => {
    if (_.isEmpty(tabSelected)) return;
    const path = tabSelected || '';
    history.push(`/${path}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  useEffect(() => {
    const path = location.pathname.substring(1, location.pathname.length);

    let initTab = {};
    switch (path) {
      case urlLabel.home:
        initTab = userRole === 'Admin' ? MENU_ITEMS[0] : MENU_ITEMS[2];
        break;
      case urlLabel.regionsManagement:
        initTab = MENU_ITEMS[0];
        break;
      case urlLabel.sitesManagement:
        initTab = MENU_ITEMS[1];
        break;
      case urlLabel.siteTracker:
        initTab = MENU_ITEMS[2];
        break;
      default:
        break;
    }

    setTabSelected(initTab.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleCollapse = menuItem => {
    const listMenu = [...menuItems];
    const pos = menuItems.findIndex(item => item.value === menuItem.value);

    listMenu[pos].isOpen = !listMenu[pos].isOpen;
    setMenuItems(listMenu);
  };

  const renderMenuItem = () => {
    return [...menuItems]
      .filter(menuItem =>
        (rolePermission[userRole] || []).includes(menuItem.value)
      )
      .map(menuItem => {
        const Icon = menuItem.icon;
        if (_.get(menuItem, 'children')) {
          return (
            <>
              <ListItem
                button
                key={menuItem.title}
                className="menu-item-custom"
                onClick={() => handleCollapse(menuItem)}
              >
                <ListItemIcon className="wrapper-icon">
                  <Icon className="icon" />
                </ListItemIcon>
                <ListItemText className="title" primary={menuItem.title} />
                {menuItem.isOpen ? (
                  <ExpandLess className="icon" />
                ) : (
                  <ExpandMore className="icon" />
                )}
              </ListItem>
              <Collapse in={menuItem.isOpen} timeout="auto" unmountOnExit>
                <List>
                  {(_.get(menuItem, 'children') || []).map(nestItem => (
                    <ListItem
                      selected={tabSelected === nestItem.value}
                      className="menu-item-nested-custom"
                      button
                      key={nestItem.value}
                      onClick={() => setTabSelected(nestItem.value)}
                    >
                      <ListItemText
                        className="title"
                        primary={nestItem.title}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          );
        }
        return (
          <ListItem
            button
            key={menuItem.title}
            selected={tabSelected === menuItem.value}
            className="menu-item-custom"
            onClick={() => setTabSelected(menuItem.value)}
          >
            <ListItemIcon className="wrapper-icon">
              <Icon className="icon" />
            </ListItemIcon>
            <ListItemText className="title" primary={menuItem.title} />
          </ListItem>
        );
      });
  };

  return (
    <div style={{ marginLeft: 12, marginRight: 12 }}>
      <List component="nav">{renderMenuItem()}</List>
    </div>
  );
};

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    userRole: state.auth.roleType
  };
};

export default connect(mapStateToProps, () => ({}))(
  withStyles(styles)(withRouter(MenuItem))
);
