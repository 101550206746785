import siteDispatcher from '../action/sites';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  listUsers: []
};

const siteReducer = siteDispatcher(initialState, {
  [siteDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [siteDispatcher.getAllUsersSuccess]: (state, payload) => ({
    listUsers: payload.data.list
  })
});

export default siteReducer;
