import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { StyledButton } from '../../module/common/componentUI/commonStyleComponents';

const useStyles = makeStyles({
  contained: {
    boxShadow: 'none'
  },
  head: {
    fontWeight: 600
  }
});

const ModalPresenter = ({
  Icon,
  title,
  children,
  onClose,
  onClick,
  cancelLabel = 'Cancel',
  confirmLabel = 'Continue',
  showFooter = true,
  style = {}
}) => {
  const classes = useStyles();

  return (
    <div style={{ ...containerStyle, ...style }}>
      <div style={bodyContainerStyle}>
        {Icon}
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 600,
            marginTop: 20,
            marginBottom: 10
          }}
        >
          {title}
        </Typography>
        {children}
      </div>
      {showFooter && (
        <div style={footerContainerStyle}>
          <StyledButton
            variant="contained"
            style={{
              color: '#192637',
              background: '#E9E9E9',
              textTransform: 'capitalize',
              margin: 5,
              paddingLeft: 30,
              paddingRight: 30,
              borderRadius: 4,
              fontWeight: 600,
              width: 'auto',
              minWidth: 160
            }}
            classes={{
              contained: classes.contained
            }}
            onClick={onClose}
          >
            {cancelLabel}
          </StyledButton>
          <StyledButton
            variant="contained"
            style={{
              color: 'white',
              background: '#102B43',
              textTransform: 'capitalize',
              margin: 5,
              paddingLeft: 30,
              paddingRight: 30,
              borderRadius: 4,
              fontWeight: 600,
              width: 'auto',
              minWidth: 160
            }}
            classes={{
              contained: classes.contained
            }}
            onClick={onClick}
          >
            {confirmLabel}
          </StyledButton>
        </div>
      )}
    </div>
  );
};

const containerStyle = {
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  letterSpacing: 0,
  position: 'fixed',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 30px 20px',
  fontSize: 14,
  justifyContent: 'center',
  alignItems: 'center'
};

const footerContainerStyle = {
  display: 'flex',
  padding: '10px 20px 10px 20px',
  flexDirection: 'row',
  borderTop: '1px #CACFD3 solid',
  justifyContent: 'center',
  alignItems: 'center'
};
export default ModalPresenter;
