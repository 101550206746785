import React, { Component } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Progress from 'react-progress-2';
import { PrivateLayout, PublicLayout } from './module/common';
import { Login } from './module/auth';
import fetchHelper from './helpers/FetchHelper';
import { Typography } from '@material-ui/core';
import { urlLabel, rolePermission } from './enum/PermissionEnum';
import { RegionManagement } from './module/region';
import { SiteManagement } from './module/sites';
import { SiteOrder } from './module/site-order';
import SiteTrack from './module/site-tracker/component/siteTrack';
import Register from './module/auth/component/Register';

fetchHelper.addBeforeRequestInterceptor(() => Progress.show());
fetchHelper.addAfterResonseInterceptor(() => Progress.hide());

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  return (
    <React.Fragment>
      <Route
        {...rest}
        render={matchProps => {
          if (
            rest.auth.accessToken &&
            rolePermission[rest.auth.roleType].includes(
              rest.path.split('/').pop()
            )
          ) {
            return (
              <PrivateLayout title={title}>
                <Component {...matchProps} />
              </PrivateLayout>
            );
          }
          return <Redirect to={`/${urlLabel.login}`} />;
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const WrapperPrivateRoute = connect(mapStateToProps)(PrivateRoute);

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      render={matchProps => {
        if (!rest.auth.accessToken)
          return (
            <PublicLayout>
              <Component {...matchProps} {...rest} />
            </PublicLayout>
          );
        return <Redirect to="/" />;
      }}
    />
  );
};

const WrapperAuthRoute = connect(mapStateToProps)(AuthRoute);

// const PublicRoute = ({ component: Component }) => {
//   return (
//     <Route
//       render={matchProps => (
//         <PublicLayout>
//           <Component {...matchProps} />
//         </PublicLayout>
//       )}
//     />
//   );
// };

class App extends Component {
  componentWillMount() {
    // authDispatcher.handleAccountState();
  }

  render() {
    return (
      <div className="App">
        <Wrapper>
          <Progress.Component />
        </Wrapper>

        <Router>
          <Switch>
            <WrapperAuthRoute
              path={`/${urlLabel.login}`}
              exact
              component={Login}
            />
            <WrapperAuthRoute
              path={`/${urlLabel.register}`}
              exact
              component={Register}
            />
            <WrapperPrivateRoute
              path={`/${urlLabel.home}`}
              component={() => <div />}
              exact
            />
            <WrapperPrivateRoute
              path={`/${urlLabel.regionsManagement}`}
              component={RegionManagement}
              exact
            />
            <WrapperPrivateRoute
              path={`/${urlLabel.sitesManagement}`}
              component={SiteManagement}
              exact
            />
            <WrapperPrivateRoute
              path={`/${urlLabel.siteOrder}`}
              component={SiteOrder}
              exact
            />
            <WrapperPrivateRoute
              path={`/${urlLabel.siteTracker}`}
              component={SiteTrack}
              exact
            />
            <Route path="*">
              <div style={{ padding: 10 }}>
                <Typography variant="h3">Not Found Page</Typography>
              </div>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

const Wrapper = styled.div`
  .loader-60devs {
    z-index: 10051;
  }
  .loader-60devs .loader-60devs-progress {
    height: 0.2rem;
    width: 100%;
    background-color: #128ff9;
  }
  /* comment below if you want react-progress-2 to block UI */
  .loader-60devs::before {
    display: none;
  }
`;

export default App;
