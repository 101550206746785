import React, { useState } from 'react';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { isEmpty } from 'lodash';
import {
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableHead
} from '@material-ui/core';
import regionDispatcher from '../action/region';

const titleContainerStyle = {
  height: 60,
  padding: '16px 20px 17px 20px',
  fontSize: 18,
  fontWeight: 600,
  color: '#192637',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px #CACFD3 solid'
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  fontSize: 14
};

const footerContainerStyle = {
  display: 'flex',
  padding: '10px 20px 10px 20px',
  flexDirection: 'row-reverse',
  borderTop: '1px #CACFD3 solid'
};

const useStyles = makeStyles({
  root: {
    padding: '0px !important'
  }
});

const RegionSitesDetail = ({ onClose, selectedItem }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      regionDispatcher.getSitesByRegionId(selectedItem.id, data => {
        setData(data.list);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFooter = () => {
    return <ButtonEnhance onClick={onClose}>Close</ButtonEnhance>;
  };

  const renderRow = row => {
    return (
      <>
        <TableCell>{row.siteName}</TableCell>
        <TableCell>{row.postCode}</TableCell>
        <TableCell>{row.address}</TableCell>
      </>
    );
  };

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCell>Site Name</TableCell>
        <TableCell>Postal Code</TableCell>
        <TableCell>Address</TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <DialogContent className={classes.root}>
      <div style={titleContainerStyle}>
        <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        {`${selectedItem.name}'s Assigned Sites`}
      </div>
      <div style={bodyContainerStyle}>
        <Table>
          <Header />
          <TableBody>
            {data.map(d => (
              <TableRow
                key={d.id}
                hover
                classes={{
                  head: classes.head
                }}
              >
                {renderRow(d)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div style={footerContainerStyle}>{renderFooter()}</div>
    </DialogContent>
  );
};

export default RegionSitesDetail;
