import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  Typography,
  withStyles,
  Divider,
  FormHelperText,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { ENTER_KEY, InputModal } from '../../../common';
import styled from 'styled-components';
import authDispatcher from '../../action/auth';
import Image from '../../../../components/Image';
import { ButtonEnhance } from '../../../common/componentUI/commonStyleComponents';
import { EmailIcon, LockIcon, EyeHide, EyeShow } from '../../../../assets/svg';
import { urlLabel } from '../../../../enum/PermissionEnum';
import { startCase } from 'lodash';
import { validateEmail } from '../../../../utils/validation';
import { useHistory } from 'react-router-dom';

const styles = () => ({
  formControl: {
    marginTop: '0px'
  }
});

const RegisterScreen = ({ classes, history }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [err, setErr] = useState({});
  const REQUIRED_FIELDS = [
    [username, 'username'],
    [password, 'password'],
    [confirmPassword, 'confirmPassword']
  ];
  const validateForm = () => {
    let err = {};
    let isValid = true;
    REQUIRED_FIELDS.some((f, index) => {
      if (!f[0]) {
        err = {
          [f[1]]: `${startCase(f[1])} is required`
        };
        isValid = false;
        return true;
      }
      if (f[1] === 'username' && !validateEmail(f[0])) {
        err = {
          [f[1]]: `Invalid email format`
        };
        isValid = false;
        return true;
      }
      return false;
    });
    if (isValid && password !== confirmPassword) {
      err = {
        confirmPassword: 'Confirm password does not match!'
      };
      isValid = false;
    }
    setErr(err);
    return isValid;
  };

  const handleRegister = () => {
    validateForm() && authDispatcher.register(username, password, history);
  };
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100vh'
      }}
    >
      <Wrapper>
        <Content>
          <Typography
            style={{
              fontSize: 36,
              letterSpacing: 0,
              fontWeight: 600
            }}
          >
            Create A New Account
          </Typography>
          <FormControlStyle className={classes.formControl}>
            <TextFieldStyle
              name="Username"
              margin="normal"
              label="Username"
              autoComplete="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            {err.username && (
              <FormHelperText style={{ color: '#b00020' }}>
                {err.username}
              </FormHelperText>
            )}
          </FormControlStyle>
          <FormControlStyle className={classes.formControl}>
            <TextFieldStyle
              type={showPassword ? '' : 'password'}
              name="Password"
              margin="normal"
              label="Password"
              autoComplete="password"
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <>
                      <EyeHide
                        style={{ cursor: 'pointer' }}
                        hidden={showPassword}
                        onClick={() => setShowPassword(true)}
                      />
                      <EyeShow
                        style={{ cursor: 'pointer' }}
                        hidden={!showPassword}
                        onClick={() => setShowPassword(false)}
                      />
                    </>
                  </InputAdornment>
                )
              }}
              onChange={e => setPassword(e.target.value)}
            />
            {err.password && (
              <FormHelperText style={{ color: '#b00020' }}>
                {err.password}
              </FormHelperText>
            )}
          </FormControlStyle>
          <FormControlStyle className={classes.formControl}>
            <TextFieldStyle
              type={showPassword ? '' : 'password'}
              name="Confirm Password"
              margin="normal"
              label="Confirm Password"
              autoComplete="password"
              value={confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <>
                      <EyeHide
                        style={{ cursor: 'pointer' }}
                        hidden={showPassword}
                        onClick={() => setShowPassword(true)}
                      />
                      <EyeShow
                        style={{ cursor: 'pointer' }}
                        hidden={!showPassword}
                        onClick={() => setShowPassword(false)}
                      />
                    </>
                  </InputAdornment>
                )
              }}
              onChange={e => setConfirmPassword(e.target.value)}
            />
            {err.confirmPassword && (
              <FormHelperText style={{ color: '#b00020' }}>
                {err.confirmPassword}
              </FormHelperText>
            )}
          </FormControlStyle>
          <ButtonEnhance
            onClick={handleRegister}
            style={{ margin: 0, marginTop: 20 }}
          >
            Register
          </ButtonEnhance>
        </Content>
      </Wrapper>
    </div>
  );
};

const CustomTypography = styled(Typography)`
  font-size: 14px !important;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 440px;
  margin-top: 10%;
`;

const Header = styled.div`
  justify-content: center;
  height: 70px;
  display: flex;
  align-items: center;
`;

const TextFieldStyle = styled(TextField)`
  margin: 0px;
`;

const FormControlStyle = styled(FormControl)`
  margin-top: 12px;
`;
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default withStyles(styles)(connect(mapStateToProps)(RegisterScreen));
