// Must be at least 8 characters, 1 uppercase and 1 special
export function validatePassword(password) {
  var regex = /^(?=.*[A-Z])(?=.*\W)[a-zA-Z0-9\S]{8,}$/;
  return regex.test(password);
}

export function validateEmail(email) {
  let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

// 8-11 numbers
export function validateMobile(mobile) {
  const regex = /\d{8,11}$/;
  return regex.test(mobile);
}
