/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import {
  makeStyles,
  Grid,
  TextField,
  Typography,
  Chip,
  Dialog,
  Stepper,
  Step,
  StepLabel
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import LoadingButton from '../../../components/LoadingButton';
import { isEmpty, unionBy, uniqBy } from 'lodash';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { useSelector } from 'react-redux';
import regionDispatcher from '../../region/action/region';
import siteDispatcher from '../../sites/action/sites';
import Api from '../../../helpers/Api';
import toastr from 'toastr';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from 'react-smooth-draggable-list';

const titleContainerStyle = {
  height: 60,
  padding: '16px 20px 17px 20px',
  fontSize: 18,
  fontWeight: 600,
  color: '#192637',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px #CACFD3 solid'
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 40px 20px',
  fontSize: 14
};

const footerContainerStyle = {
  display: 'flex',
  padding: '10px 20px 10px 20px',
  flexDirection: 'row-reverse',
  borderTop: '1px #CACFD3 solid'
};

const useStyles = makeStyles({
  root: {
    padding: '0px !important'
  }
});

const steps = [
  'Name this Runsheet',
  'Select Region',
  'Select Postal Code',
  'Select and order Site'
];

const RunsheetModal = ({
  editable,
  onClose,
  onSuccess,
  searchKey,
  selectedItem,
  open
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [region, setRegion] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [postalCodes, setPostalCodes] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSites] = useState([]);
  const [order, setOrder] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [user, setUser] = useState(null);
  const [runsheetId, setRunsheetId] = useState('');
  const regionList = useSelector(state => state.region.list);
  const listUsers = useSelector(state => state.site.listUsers);

  const selectedPostcodes = useMemo(() => unionBy(selectedSite, 'postCode'), [
    selectedSite
  ]);
  useEffect(() => {
    if (!open) return;
    regionDispatcher.getData(undefined, undefined, true);
    siteDispatcher.getAllUsers();
    if (!isEmpty(selectedItem)) {
      parseData(selectedItem);
    } else resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, open]);

  const parseData = async runsheet => {
    let fetchRegionDetail = Api.get(`Regions/id?id=${runsheet.regionId}`);
    let [regionDetailRs] = await Promise.all([fetchRegionDetail]);
    if (regionDetailRs.status === 200 && regionDetailRs.result)
      setRegion(regionDetailRs.result);
    setName(runsheet.name);
    setPostalCode({
      number: runsheet.postalCode
    });
    setUser({
      email: runsheet.userEmail,
      id: runsheet.userId
    });
    setSelectedSites(runsheet.data);
    setRunsheetId(runsheet.id);
  };

  const resetState = () => {
    setActiveStep(0);
    setName('');
    setRegion(null);
    setPostalCode(null);
    setPostalCodes([]);
    setSites([]);
    setSelectedSites([]);
    setOrder(null);
    setUser(null);
  };

  const onCloseDialog = () => {
    resetState();
    onClose();
  };

  const onSave = async () => {
    let items = order
      ? order.map((selectedIndex, index) => ({
          siteId: selectedSite[selectedIndex].pkSiteID,
          index
        }))
      : selectedSite.map((s, index) => ({
          siteId: s.pkSiteID,
          index
        }));
    let api =
      runsheetId === ''
        ? Api.post(`SiteOrders`, {
            name,
            items,
            userId: user.id,
            regionId: region.id,
            postalCode: postalCode.number
          })
        : Api.put(`SiteOrders/id?id=${runsheetId}`, {
            name,
            items,
            userId: user.id,
            regionId: region.id,
            postalCode: postalCode.number
          });
    let { result, status } = await api;
    if (status === 200) {
      toastr.success('Success');
      onSuccess();
      setIsSuccess(result.list[0]);
      resetState();
    }
  };

  const fetchPostalCodes = async () => {
    let { result, status } = await Api.get(`PostalCodes`, {
      regionId: region.id
    });
    if (status === 200) {
      setPostalCodes(uniqBy(result.list, i => i.number));
    }
  };

  const fetchSites = async () => {
    let { result, status } = await Api.get(`Sites`, {
      postalCode: postalCode.number,
      regionId: region.id
    });
    if (status === 200) {
      setSites(result.list);
    }
  };

  useEffect(() => {
    if (name && !activeStep) {
      setIsSuccess(false);
      setActiveStep(1);
    }
  }, [name]);

  useEffect(() => {
    setSites([]);
    setPostalCode('');
    setPostalCodes([]);
    setSelectedSites([]);
    setOrder(null);
    if (!region) {
      return setActiveStep(activeStep ? 1 : 0);
    }
    setIsSuccess(false);
    fetchPostalCodes();
    setActiveStep(2);
  }, [region]);

  useEffect(() => {
    if (!postalCode) {
      if (region) {
        setSites([]);
        return setActiveStep(2);
      } else return;
    }
    fetchSites();
    setActiveStep(3);
  }, [postalCode]);

  useEffect(() => {
    setOrder(null);
  }, [selectedSite]);

  const someEmpty = arr => arr.some(i => isEmpty(i));

  const renderFooter = () => {
    let isAdd = runsheetId === '';
    if (isAdd)
      return (
        <>
          <LoadingButton
            onClick={onSave}
            disabled={someEmpty([name, region, postalCode, user, selectedSite])}
          >
            Create
          </LoadingButton>
          <ButtonEnhance
            onClick={onCloseDialog}
            color="#192637"
            background="#E9E9E9"
            backgroundHover="#bababa"
          >
            Cancel
          </ButtonEnhance>
        </>
      );
    else {
      return (
        <>
          <LoadingButton
            onClick={onSave}
            disabled={someEmpty([name, region, postalCode, user, selectedSite])}
          >
            Save
          </LoadingButton>
          <ButtonEnhance
            onClick={onCloseDialog}
            color="#192637"
            background="#E9E9E9"
            backgroundHover="#bababa"
          >
            Cancel
          </ButtonEnhance>
        </>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      scroll="body"
      maxWidth="md"
      fullWidth
    >
      <DialogContent className={classes.root}>
        <div style={titleContainerStyle}>
          <ClearIcon style={{ cursor: 'pointer' }} onClick={onCloseDialog} />
          {selectedItem.name || 'Create New Runsheet'}
        </div>
        <div style={bodyContainerStyle}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={'Name'}
                value={name}
                required
                onChange={e => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={region}
                onChange={(event, newValue) => {
                  setRegion(newValue);
                }}
                id="region"
                fullWidth
                options={regionList}
                getOptionLabel={option => option.name || ''}
                renderInput={params => (
                  <TextField {...params} label="Region" required />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={postalCode}
                onChange={(event, newValue) => {
                  setPostalCode(newValue);
                }}
                id="postal-code"
                fullWidth
                options={postalCodes}
                getOptionLabel={option => option.number || ''}
                renderInput={params => (
                  <TextField {...params} label="Postal Code" required />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={user}
                onChange={(event, newValue) => {
                  setUser(newValue);
                }}
                id="user-assigned"
                fullWidth
                options={listUsers}
                getOptionLabel={option => option.email || ''}
                renderInput={params => (
                  <TextField {...params} label="Inspector" required />
                )}
              />
            </Grid>
            {!isEmpty(selectedPostcodes) && (
              <Grid item xs={12}>
                <Typography
                  style={{ fontWeight: 600, marginTop: 5, marginBottom: 5 }}
                >
                  Selected Postcodes
                </Typography>
                {selectedPostcodes.map(s => (
                  <Chip
                    label={s.postCode}
                    style={{ marginRight: 5, marginBottom: 5 }}
                    clickable
                    color="primary"
                  />
                ))}
              </Grid>
            )}
            {!isEmpty(sites) && (
              <Grid item xs={12}>
                <Typography
                  style={{ fontWeight: 600, marginTop: 5, marginBottom: 5 }}
                >
                  Sites*
                </Typography>
                {sites.map(s => (
                  <Chip
                    label={s.siteName}
                    style={{ marginRight: 5, marginBottom: 5 }}
                    clickable
                    onClick={() => {
                      let isSelected = selectedSite.find(
                        ss => ss.pkSiteID === s.pkSiteID
                      );
                      if (isSelected) {
                        setSelectedSites(
                          selectedSite.filter(ss => ss.pkSiteID !== s.pkSiteID)
                        );
                      } else if (selectedSite.length >= 7) {
                        return toastr.warning(
                          'Too much sites, the maximum number of sites is 7'
                        );
                      } else {
                        setSelectedSites([...selectedSite, s]);
                      }
                    }}
                    color={
                      selectedSite.find(ss => ss.pkSiteID === s.pkSiteID)
                        ? 'primary'
                        : undefined
                    }
                  />
                ))}
              </Grid>
            )}
            {!isEmpty(selectedSite) && (
              <Grid item xs={12}>
                <>
                  <Typography
                    style={{ fontWeight: 600, marginTop: 5, marginBottom: 5 }}
                  >
                    Site Runsheet (Drag and Drop to change Order)
                  </Typography>
                  <List rowHeight={32} onReOrder={setOrder}>
                    {selectedSite.map(s => (
                      <List.Item>
                        <Chip
                          label={s.siteName}
                          clickable
                          onDelete={() => {
                            setSelectedSites(
                              selectedSite.filter(
                                ss => ss.pkSiteID !== s.pkSiteID
                              )
                            );
                          }}
                        />
                      </List.Item>
                    ))}
                  </List>
                </>
              </Grid>
            )}
          </Grid>
        </div>
        <div style={footerContainerStyle}>{renderFooter()}</div>
      </DialogContent>
    </Dialog>
  );
};

export default RunsheetModal;
