import React, { useState, useEffect } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import siteTrackDispatcher from '../action/siteTrack';
import { InfoRounded, CreateRounded, DeleteRounded } from '@material-ui/icons';
import MapDialog from './MapDialog';
import { useLocation, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { urlLabel } from '../../../enum/PermissionEnum';
import RunsheetModal from './RunsheetModal';
import { renderIf } from '../../../helpers';
import DeleteModal from '../../../components/DeleteModal';
const SiteTrack = () => {
  const location = useLocation();
  const history = useHistory();
  const data = useSelector(state => state.siteTrack.list);
  const paging = useSelector(state => state.siteTrack.paging);
  const { roleType: userRole } = useSelector(state => state.auth);

  const [searchKey, setSearchKey] = useState('');
  const [hiddenRows, setHiddenRows] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [showRunsheetModal, setShowRunsheetModal] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const handleViewDetail = item => {
    setSelectedItem(item);
    setShowMapModal(true);
  };

  const onCloseMapModal = () => {
    setSelectedItem({});
    setShowMapModal(false);
  };

  useEffect(() => {
    location.siteOrderData && setSelectedItem(location.siteOrderData);
  }, [location]);

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          style={{ paddingLeft: 20 }}
          label="ID"
          stateValue="id"
        />
        <TableCellFiltedWrapper label="Name" stateValue="name" />
        <TableCellFiltedWrapper label="Sites" stateValue="sites" />
        <TableCellFiltedWrapper label="Inspector" stateValue="user" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const onCreateRunsheet =
    userRole === 'Admin'
      ? () => {
          setShowRunsheetModal(true);
        }
      : undefined;

  const onEditRunsheet = item => {
    setSelectedItem(item);
    setShowRunsheetModal(true);
  };

  const onCloseRunsheetModal = () => {
    setSelectedItem({});
    setShowRunsheetModal(false);
  };

  const onAddOrEditRunsheetSuccess = () => {
    setSelectedItem({});
    setShowRunsheetModal(false);
    siteTrackDispatcher.getData(searchKey);
  };

  const handleDeleteRunsheet = () => {
    setDeleteModal(false);
    siteTrackDispatcher.deleteSiteOrder(selectedItem.id, searchKey);
  };
  const onDeleteRunsheet = item => {
    setSelectedItem(item);
    setDeleteModal(true);
  };

  const onCloseDelRunsheet = () => {
    setSelectedItem({});
    setDeleteModal(false);
  };

  return (
    <>
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          selectedItem={selectedItem}
          onClose={onCloseDelRunsheet}
          onDelete={handleDeleteRunsheet}
          modalName="Runsheet"
        />
      )}
      <MapDialog
        open={showMapModal}
        selectedItem={selectedItem}
        onClose={onCloseMapModal}
      />
      <RunsheetModal
        selectedItem={selectedItem}
        open={showRunsheetModal}
        onClose={onCloseRunsheetModal}
        onSuccess={onAddOrEditRunsheetSuccess}
        searchKey={searchKey}
      />
      <TableContainer
        title="Site Tracker"
        query={siteTrackDispatcher.getData}
        header={Header}
        paging={paging}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        data={data}
        onAddNew={onCreateRunsheet}
        renderRow={d => (
          <>
            <TableCell style={{ paddingLeft: 20 }}>
              {!hiddenRows.id && d.id}
            </TableCell>
            <TableCell>{!hiddenRows.name && d.name}</TableCell>
            <TableCell>
              {!hiddenRows.sites &&
                (d.data || []).map(d => d.siteName).join(', ')}
            </TableCell>
            <TableCell>{get(d, 'userEmail', '')}</TableCell>
            <TableCell align="right">
              <div style={{ display: 'flex' }}>
                <IconButton onClick={() => handleViewDetail(d)}>
                  <Tooltip title="View Detail">
                    <InfoRounded />
                  </Tooltip>
                </IconButton>
                {renderIf(userRole === 'Admin')(
                  <>
                    <IconButton
                      onClick={() => {
                        onEditRunsheet(d);
                      }}
                    >
                      <Tooltip title="Edit">
                        <CreateRounded />
                      </Tooltip>
                    </IconButton>
                    <IconButton onClick={() => onDeleteRunsheet(d)}>
                      <Tooltip title="Delete">
                        <DeleteRounded />
                      </Tooltip>
                    </IconButton>
                  </>
                )}
              </div>
            </TableCell>
          </>
        )}
      />
    </>
  );
};

export default SiteTrack;
