/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip
} from '@material-ui/core';
import regionDispatcher from '../../region/action/region';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Api from '../../../helpers/Api';
import List from 'react-smooth-draggable-list';
import { isEmpty, uniqBy } from 'lodash';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import toastr from 'toastr';
import styled from 'styled-components';
import { urlLabel } from '../../../enum/PermissionEnum';
import { useHistory, useLocation } from 'react-router-dom';
import siteDispatcher from '../../sites/action/sites';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: '15%',
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    },
    maxWidth: '70%'
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}));

const steps = [
  'Name this Runsheet',
  'Select Region',
  'Select Postal Code',
  'Select and order Site'
];

const SiteOrder = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [region, setRegion] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [postalCodes, setPostalCodes] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSites] = useState([]);
  const [order, setOrder] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [user, setUser] = useState(null);
  const [runsheetId, setRunsheetId] = useState('');
  const regionList = useSelector(state => state.region.list);
  const listUsers = useSelector(state => state.site.listUsers);

  useEffect(() => {
    regionDispatcher.getData(undefined, undefined, true);
    siteDispatcher.getAllUsers();
    const { runsheet } = location;
    if (runsheet) {
      parseData(runsheet);
    }
  }, []);

  const parseData = async runsheet => {
    let fetchRegionDetail = Api.get(`Regions/id?id=${runsheet.regionId}`);
    let [regionDetailRs] = await Promise.all([fetchRegionDetail]);
    if (regionDetailRs.status === 200 && regionDetailRs.result)
      setRegion(regionDetailRs.result);
    setName(runsheet.name);
    setPostalCode({
      number: runsheet.postalCode
    });
    setUser({
      email: runsheet.userEmail,
      id: runsheet.userId
    });
    setSelectedSites(runsheet.data);
    setRunsheetId(runsheet.id);
  };

  const resetState = () => {
    setActiveStep(0);
    setName('');
    setRegion(null);
    setPostalCode(null);
    setPostalCodes([]);
    setSites([]);
    setSelectedSites([]);
    setOrder(null);
    setUser(null);
  };

  const onSave = async () => {
    let items = order
      ? order.map((selectedIndex, index) => ({
          siteId: selectedSite[selectedIndex].pkSiteID,
          index
        }))
      : selectedSite.map((s, index) => ({
          siteId: s.pkSiteID,
          index
        }));
    let api =
      runsheetId === ''
        ? Api.post(`SiteOrders`, {
            name,
            items,
            userId: user.id,
            regionId: region.id,
            postalCode: postalCode.number
          })
        : Api.put(`SiteOrders/id?id=${runsheetId}`, {
            name,
            items,
            userId: user.id,
            regionId: region.id,
            postalCode: postalCode.number
          });
    let { result, status } = await api;
    if (status === 200) {
      toastr.success('Success');
      setIsSuccess(result.list[0]);
      resetState();
    }
  };

  const fetchPostalCodes = async () => {
    let { result, status } = await Api.get(`PostalCodes`, {
      regionId: region.id
    });
    if (status === 200) {
      setPostalCodes(uniqBy(result.list, i => i.number));
    }
  };

  const fetchSites = async () => {
    let { result, status } = await Api.get(`Sites`, {
      Search: postalCode.number
    });
    if (status === 200) {
      setSites(result.list);
    }
  };

  useEffect(() => {
    if (name && !activeStep) {
      setIsSuccess(false);
      setActiveStep(1);
    }
  }, [name]);

  useEffect(() => {
    setSites([]);
    setPostalCode('');
    setPostalCodes([]);
    setSelectedSites([]);
    setOrder(null);
    if (!region) {
      return setActiveStep(activeStep ? 1 : 0);
    }
    setIsSuccess(false);
    fetchPostalCodes();
    setActiveStep(2);
  }, [region]);

  useEffect(() => {
    if (!postalCode) {
      if (region) {
        setSites([]);
        return setActiveStep(2);
      } else return;
    }
    fetchSites();
    setActiveStep(3);
  }, [postalCode]);

  useEffect(() => {
    setOrder(null);
  }, [selectedSite]);

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          Runsheet
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={'Name'}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={region}
              onChange={(event, newValue) => {
                setRegion(newValue);
              }}
              id="region"
              fullWidth
              options={regionList}
              getOptionLabel={option => option.name || ''}
              renderInput={params => <TextField {...params} label="Region" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={postalCode}
              onChange={(event, newValue) => {
                setPostalCode(newValue);
              }}
              id="postal-code"
              fullWidth
              options={postalCodes}
              getOptionLabel={option => option.number || ''}
              renderInput={params => (
                <TextField {...params} label="Postal Code" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={user}
              onChange={(event, newValue) => {
                setUser(newValue);
              }}
              id="user-assigned"
              fullWidth
              options={listUsers}
              getOptionLabel={option => option.email || ''}
              renderInput={params => (
                <TextField {...params} label="Inspector" />
              )}
            />
          </Grid>
          {!isEmpty(sites) && (
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: 600, marginTop: 5, marginBottom: 5 }}
              >
                Sites
              </Typography>
              {sites.map(s => (
                <Chip
                  label={s.siteName}
                  style={{ marginRight: 5, marginBottom: 5 }}
                  clickable
                  onClick={() => {
                    let isSelected = selectedSite.find(
                      ss => ss.pkSiteID === s.pkSiteID
                    );
                    if (isSelected) {
                      setSelectedSites(
                        selectedSite.filter(ss => ss.pkSiteID !== s.pkSiteID)
                      );
                    } else {
                      setSelectedSites([...selectedSite, s]);
                    }
                  }}
                  color={
                    selectedSite.find(ss => ss.pkSiteID === s.pkSiteID)
                      ? 'primary'
                      : undefined
                  }
                />
              ))}
            </Grid>
          )}
          {!isEmpty(selectedSite) && (
            <Grid item xs={12}>
              <>
                <Typography
                  style={{ fontWeight: 600, marginTop: 5, marginBottom: 5 }}
                >
                  Order Here
                </Typography>
                <List rowHeight={32} onReOrder={setOrder}>
                  {selectedSite.map(s => (
                    <List.Item>
                      <Chip
                        label={s.siteName}
                        clickable
                        onDelete={() => {
                          setSelectedSites(
                            selectedSite.filter(
                              ss => ss.pkSiteID !== s.pkSiteID
                            )
                          );
                        }}
                      />
                    </List.Item>
                  ))}
                </List>
              </>
            </Grid>
          )}

          <Grid item xs={12}>
            {isSuccess ? (
              <ButtonEnhance
                color="white"
                background="#34AA7F"
                backgroundHover="#216e52"
                style={{ marginLeft: 0, marginRight: 0 }}
                onClick={() => {
                  history.push({
                    pathname: `/${urlLabel.siteTracker}`,
                    siteOrderData: isSuccess
                  });
                }}
              >
                View Detail
              </ButtonEnhance>
            ) : (
              <ButtonEnhance
                disabled={isEmpty(selectedSite) || isEmpty(user)}
                onClick={
                  isEmpty(selectedSite) || isEmpty(user) ? () => null : onSave
                }
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                Save
              </ButtonEnhance>
            )}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default SiteOrder;
