import { combineReducers } from 'redux';
import { authReducer } from '../module/auth';
import { regionReducer } from '../module/region';
import { siteReducer } from '../module/sites';
import siteTrackReducer from '../module/site-tracker/reducer/siteTrack';

export default combineReducers({
  ...authReducer,
  ...regionReducer,
  ...siteReducer,
  ...siteTrackReducer
});
