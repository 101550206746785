import { synthesize } from 'redux-dispatcher';
import Api from '../../../helpers/Api';
import moment from 'moment';
import fetchHelper from '../../../helpers/FetchHelper';
import { urlLabel } from '../../../enum/PermissionEnum';

function saveAuthorizationToken(accessToken) {
  fetchHelper.addDefaultHeader('Authorization', `Bearer ${accessToken}`);
}
const mapDispatchToAC = {
  loginSuccess: (token, userName) => ({ token, userName }),
  login: (email, password, callback) => async ({ Api, toastr }) => {
    const body = {
      email,
      password
    };
    let { result, status } = await Api.post(`Users/login`, body);
    if (status === 200) {
      authDispatcher.loginSuccess(result, email);
      callback(result);
      return result; //done
    }
  },

  register: (email, password, history) => async ({ Api, toastr }) => {
    const body = {
      email,
      password
    };
    const { status } = await Api.post('Users/register', body);
    if (status === 200) {
      toastr.success('Register success');
      history.push(`/${urlLabel.home}`);
    }
  },

  checkOtpSuccess: data => ({ data }),
  checkOtp: (history, result) => async ({ toastr }) => {
    saveAuthorizationToken(result.accessToken);
    authDispatcher.checkOtpSuccess(result);
    toastr.success('Login Success');
  },

  logoutSuccess: () => ({}),
  logout: () => async () => {
    saveAuthorizationToken('');
    authDispatcher.logoutSuccess();
  },
  getUserDetailSuccess: userInfo => ({ userInfo }),
  getUserDetail: id => async () => {
    let { result, status } = await Api.get(`Users/${id}`);
    if (status === 200) {
    }
  },
  handleAccountState: () => async ({ getState }) => {
    const { accessToken, expireOn } = getState().auth;
    let currentTime = moment().valueOf() / 1000;
    let expireTime = moment(expireOn).valueOf() / 1000 || 0;
    if (!accessToken || currentTime > expireTime) {
      authDispatcher.logoutSuccess();
    } else {
      saveAuthorizationToken(accessToken);
    }
  }
};

const authDispatcher = synthesize('auth', mapDispatchToAC);
export default authDispatcher;
