/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  DirectionsRenderer
} from 'react-google-maps';
import { DialogContent, makeStyles, Dialog } from '@material-ui/core';
import siteTrackDispatcher from '../action/siteTrack';
import { ClearRounded } from '@material-ui/icons';
import toastr from 'toastr';
const {
  MarkerWithLabel
} = require('react-google-maps/lib/components/addons/MarkerWithLabel');

const titleContainerStyle = {
  height: 60,
  padding: '16px 20px 17px 20px',
  fontSize: 18,
  fontWeight: 600,
  color: '#192637',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px #CACFD3 solid'
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14
};

const useStyles = makeStyles({
  root: {
    padding: '0px !important'
  }
});

const MapDialog = ({ selectedItem, onClose, open }) => {
  const classes = useStyles();
  const [siteOrder, setSiteOrder] = useState([]);
  const [name, setName] = useState('');
  useEffect(() => {
    if (!isEmpty(selectedItem) && open) {
      siteTrackDispatcher.getSiteOrderDetail(selectedItem.id, result => {
        setName(result.name);
        setSiteOrder(result.data);
      });
    } else {
      setSiteOrder([]);
    }
  }, [selectedItem, open]);

  return (
    <Dialog open={open} onClose={onClose} scroll="body" maxWidth="lg" fullWidth>
      <DialogContent className={classes.root}>
        <div style={titleContainerStyle}>
          <ClearRounded style={{ cursor: 'pointer' }} onClick={onClose} />
          {name}
        </div>
        <div style={bodyContainerStyle}>
          <MapComponent
            siteOrder={siteOrder}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `550px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const DEFAULT_POINT = {
  sitelatitude: -33.8824095,
  sitelongitude: 151.2051852,
  siteName: 'Sydney CBD'
};

const MapComponent = withScriptjs(
  withGoogleMap(({ siteOrder }) => {
    if (isEmpty(siteOrder)) {
      return <div style={{ height: 550 }} />;
    }
    const travelMode = window.google.maps.TravelMode.DRIVING;
    const mapRef = useRef();
    const [directions, setDirections] = useState([]);
    const [displayMarkers, setDisplayMarker] = useState([]);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
      if (siteOrder) {
        const waypoints = siteOrder.map(p => ({
          location: { lat: p.sitelatitude, lng: p.sitelongitude },
          stopover: true
        }));
        // If only one site was selected
        if (siteOrder.length === 1) {
          return;
        }
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: travelMode,
            waypoints: waypoints
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              let markers = [];
              result.routes[0].legs.forEach((item, index) => {
                item.start_address = siteOrder[index].siteName;
                item.end_address = siteOrder[index + 1].siteName;
                if (!index)
                  markers.push({
                    sitelatitude: item.start_location.lat(),
                    sitelongitude: item.start_location.lng(),
                    siteName: siteOrder[index].siteName
                  });
                markers.push({
                  sitelatitude: item.end_location.lat(),
                  sitelongitude: item.end_location.lng(),
                  siteName: siteOrder[index + 1].siteName
                });
              });
              setDisplayMarker(markers);
              setDirections(result);
            } else {
              console.log('direction err: ', result);
              setIsError(true);
              switch (status) {
                case 'ZERO_RESULTS':
                  toastr.error(`Can't get directions between these routes`);
                  break;
                default:
                  toastr.error(
                    `Error when fetching directions: ${result.status}`
                  );
                  break;
              }
            }
          }
        );
      }
    }, [siteOrder]);
    return (
      <GoogleMap
        ref={mapRef}
        defaultZoom={8}
        center={{
          lat: !isError
            ? siteOrder[0].sitelatitude
            : DEFAULT_POINT.sitelatitude,
          lng: !isError
            ? siteOrder[0].sitelongitude
            : DEFAULT_POINT.sitelongitude
        }}
      >
        {isError ? (
          <MarkerWithLabel
            noRedraw
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 4,
              strokeWeight: 2,
              fillOpacity: 1,
              fillColor: '#ff3f10',
              strokeColor: '#ffffff'
            }}
            position={{
              lat: DEFAULT_POINT.sitelatitude,
              lng: DEFAULT_POINT.sitelongitude
            }}
            labelAnchor={new window.google.maps.Point(20, 30)}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 4,
                padding: 4,
                fontWeight: 600
              }}
            >
              {DEFAULT_POINT.siteName}
            </div>
          </MarkerWithLabel>
        ) : (
          (isEmpty(displayMarkers) ? siteOrder : displayMarkers).map(site => (
            <MarkerWithLabel
              noRedraw
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 4,
                strokeWeight: 2,
                fillOpacity: 1,
                fillColor: '#ff3f10',
                strokeColor: '#ffffff'
              }}
              position={{ lat: site.sitelatitude, lng: site.sitelongitude }}
              labelAnchor={new window.google.maps.Point(20, 30)}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: 4,
                  padding: 4,
                  fontWeight: 600
                }}
              >
                {site.siteName}
              </div>
            </MarkerWithLabel>
          ))
        )}
        {!isError && (
          <DirectionsRenderer
            directions={directions}
            options={{
              suppressMarkers: true
            }}
          />
        )}
      </GoogleMap>
    );
  })
);

export default MapDialog;
