import React from 'react';
import { CreateRounded, DeleteRounded, InfoRounded } from '@material-ui/icons';
import { MenuItem, ListItemIcon } from '@material-ui/core';
import styled from 'styled-components';

const MenuAction = ({ onShowModal, onDel, onShowSiteModal }) => {
  let listActions = [
    {
      key: 'view',
      icon: InfoRounded,
      label: 'View Sites',
      onClick: () => onShowSiteModal(true)
    },
    {
      key: 'edit',
      icon: CreateRounded,
      label: 'Edit Region',
      onClick: () => onShowModal(true, true)
    },
    {
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete Region',
      onClick: onDel
    }
  ];

  return listActions.map(action => {
    const Icon = action.icon;
    return (
      <MenuItemAction
        key={action.key}
        onClick={() => {
          action.onClick();
        }}
      >
        <ListItemMenuIcon style={{ minWidth: 34 }}>
          <Icon className="icon" />
        </ListItemMenuIcon>
        {action.label}
      </MenuItemAction>
    );
  });
};

const MenuItemAction = styled(MenuItem)`
  display: flex;
  align-items: center;
  color: #192637;
`;

const ListItemMenuIcon = styled(ListItemIcon)`
  .icon {
    color: #cacfd3;
  }
  svg {
    color: #cacfd3;
  }
`;

export default MenuAction;
