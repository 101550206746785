import { synthesize } from 'redux-dispatcher';
const mapDispatchToAC = {
  getDataSuccess: data => ({
    data
  }),
  getData: (search, paging, getAll = false) => async ({ Api, getState }) => {
    let pagingData = paging || getState().region.paging;
    let { result, status } = await Api.get(
      `Regions`,
      getAll
        ? undefined
        : {
            pageIndex: pagingData.pageIndex,
            pageSize: pagingData.pageSize,
            search
          }
    );
    if (status === 200) {
      regionDispatcher.getDataSuccess(result);
    }
  },
  createRegion: (data, searchKey, callback) => async ({
    Api,
    getState,
    toastr
  }) => {
    let { result, status } = await Api.post(`Regions`, data);
    if (status === 200) {
      let { paging } = getState().region;
      regionDispatcher.getData(searchKey, paging);
      toastr.success('Success');
      callback();
    }
  },
  updateRegion: (data, searchKey, callback) => async ({
    Api,
    getState,
    toastr
  }) => {
    let { result, status } = await Api.put(`Regions/id?id=${data.id}`, data);
    if (status === 200) {
      let { paging } = getState().region;
      regionDispatcher.getData(searchKey, paging);
      toastr.success('Success');
      callback();
    }
  },
  deleteRegion: (id, searchKey) => async ({ Api, getState, toastr }) => {
    let { result, status } = await Api.delete(`Regions/id?id=${id}`);
    if (status === 200) {
      let { paging } = getState().region;
      regionDispatcher.getData(searchKey, paging);
      toastr.success('Success');
    }
  },
  getSitesByRegionId: (id, onSuccess) => async ({ Api, toastr }) => {
    let { result, status } = await Api.get(`Sites`, {
      regionId: id
    });
    if (status === 200) {
      onSuccess(result);
    }
  }
};

const regionDispatcher = synthesize('region', mapDispatchToAC);
export default regionDispatcher;
