import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import configureStore from './redux/store';
import App from './App';
import 'toastr/toastr.scss';
import './index.css';
import './assets/css/global.scss';
import * as serviceWorker from './serviceWorker';
import toastr from 'toastr';

toastr.options.timeOut = 1500;
toastr.options.extendedTimeOut = 0;
toastr.options.tapToDismiss = true;

export const configure = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#102B43'
    },
    secondary: {
      main: '#8F9BA9'
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: 'Roboto',
    body1: {
      fontSize: 14
    }
  },
  overrides: {
    MuiDrawer: {
      paper: {
        width: '300px'
      }
    },
    MuiInputBase: {
      input: {
        marginLeft: 12,
        paddingBottom: 9,
        paddingTop: 9
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '1px solid #102B43'
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.87)'
        }
      }
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        verticalAlign: 'bottom'
      },
      body: {
        color: 'rgba(0, 0, 0, 0.87)',
        verticalAlign: 'top'
      }
    },
    MuiSelect: {
      select: {
        background: 'none !important'
      }
    }
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={configure.store}>
      <PersistGate loading={null} persistor={configure.persistor}>
        <App />
      </PersistGate>
    </Provider>
  </MuiThemeProvider>,

  document.getElementById('root')
);

serviceWorker.register();
